import { Menu as MenuIcon, NotificationAddRounded, NotificationImportant, Notifications, NotificationsNone } from "@mui/icons-material";
import {
  AppBar,
  Badge,
  Button,
  Card,
  CardContent,
  Fade,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  IconButton as MuiIconButton,
  Paper,
  Popper,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material";
import { darken } from "polished";
import React, { useEffect, useState } from "react";
import { MdNotifications, MdPayment } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";
import * as types from "../constants";
import UsuarioService from "../services/UsuarioService";
import Alert from "./alert/Alert";

import EmpresaComplete from "./icarus/EmpresaComplete";
import MessagesDropdown from "./MessagesDropdown";
import NotificationsDropdown from "./NotificationsDropdown";
import UserDropdown from "./UserDropdown";
import { Info } from "react-feather";
import ContratoService from "../services/ContratoService";
import MaskUtil from "../utils/MaskUtil";
import { format } from "date-fns";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  
  display: block;
  position: relative;
  width: 100%;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
`;

const SearchButton = styled.div`
  border-radius: 2px;
 
  display: none;
  position: relative;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const InputEmpresa = styled(EmpresaComplete)`
  color: inherit;
  padding: 8px;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const AppBarComponent = ({ onDrawerToggle, theme }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();

  const [contratosVencimento, setContratosVencimento] = useState();

  useEffect(() => {
    ContratoService.contratoVencimento().then((response) => {
      console.log(response)
      setContratosVencimento(response)
    })
  }, [])


  const changeEmpresa = (value) => {
    UsuarioService.changeCompany(value.idEmpresa).then((response) => {
      dispatch({ type: types.CHANGE_EMPRESA, user: response });
      Alert.success("Sucesso", "Empresa alterada com sucesso");
      history.go(0);
    });
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0} style={{ background: theme.header.background, color: theme.header.color }}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  size="large">
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xl={9} md={9}>
              <Grid container>
                {(user.profile.papel === "SUPORTE" ||
                  user.profile.papel === "RECRUTAMENTO" ||
                  (user.profile.papel === "ADMINISTRADORA" &&
                    user.companyLinked &&
                    user.companyLinked.length > 0)) && (
                    <Grid item md={4}>
                      <Search>
                        <InputEmpresa
                          value={user.company}
                          onChange={(value) => {
                            changeEmpresa(value);
                          }}
                        />
                      </Search>
                    </Grid>
                  )}

                {user.company.diasFaltantesTrial !== undefined &&
                  user.company.diasFaltantesTrial !== null &&
                  (user.profile.papel === "ADMINISTRADORA" ||
                    user.profile.papel === "SUPORTE" ||
                    user.profile.papel === "RECRUTAMENTO") && (
                    <Grid item md={4} style={{ alignSelf: "center" }}>
                      <SearchButton>
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{ height: 53 }}
                          startIcon={<MdPayment />}
                          onClick={() => history.push("/planos")}
                        >
                          <div
                            style={{
                              flexDirection: "row",
                              fontSize: 12,
                              fontWeight: "600",
                            }}
                          >
                            {user.company.diasFaltantesTrial < 0 ? (
                              <text style={{ color: "red" }}>
                                Seu período de avaliação terminou
                              </text>
                            ) : (
                              <text>
                                Seu período de avaliação termina em{" "}
                                {user.company.diasFaltantesTrial} dias
                              </text>
                            )}
                          </div>
                        </Button>
                      </SearchButton>
                    </Grid>
                  )}
              </Grid>
            </Grid>
            <Popper
              sx={{ zIndex: 1200 }}
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              onMouseLeave={() => setOpen(false)}
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Grid width={350} marginLeft={10} marginRight={10}>
                    <Card sx={{ maxHeight: 400, overflowY: "scroll" }} variant="outlined">
                      <List
                        subheader={
                          <ListSubheader component="div" id="nested-list-subheader">
                            Contratos vencimento
                          </ListSubheader>
                        }
                      >
                        {contratosVencimento?.length ? contratosVencimento?.map((contrato) => {
                          return <ListItem disablePadding>
                            <ListItemButton component="a" href={`/contrato/detalhe/${contrato.idContrato}`} target="_blank">
                              <ListItemIcon>
                                <Info />
                              </ListItemIcon>
                              <ListItemText primary={`Cod. Contrato: ${contrato.idContrato}`} secondary={`Fim: ${format(new Date(contrato.fimContrato), "dd/MM/yyyy")} - Valor: ${MaskUtil.formataMoeda(contrato.aporte)}`} />
                            </ListItemButton>
                          </ListItem>
                        }) : <Grid container p={5} textAlign="center" justifyContent="center" alignItems="center"><Typography variant="caption">Você não possui contratos</Typography></Grid>}
                      </List>
                    </Card>
                  </Grid>
                </Fade>
              )}
            </Popper>
            <Grid item xs />
            <Grid item>
              <IconButton
                sx={{
                  p: 3
                }}
                onClick={(event) => {
                  setOpen(true)
                  setAnchorEl(event.currentTarget);
                }} variant="contained" color="info">
                <Tooltip title="Notificações">
                  <Badge color="error" badgeContent={contratosVencimento?.length || 0}>
                    <MdNotifications />
                  </Badge>
                </Tooltip>
              </IconButton>
              <MessagesDropdown />
              <NotificationsDropdown />
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AppBarComponent);
