import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, Typography, Grid } from "@mui/material";
const FieldSet = ({ title, hasbackground, hasborder, children }) => {
  return (
    <>
      {hasbackground ? (
        <Grid
          sx={{
            backgroundColor: "#f5f5f5",
            padding: "14px",
            border: hasborder ? "2px solid red" : "none",
          }}
        >
          <Typography variant="h6">{title}</Typography>
          <Box my={2} />
          <Divider />
          <Box my={5} />
          {children}
        </Grid>
      ) : (
        <>
          <Typography variant="h6">{title}</Typography>
          <Box my={2} />
          <Divider />
          <Box my={5} />
          {children}
        </>
      )}
    </>
  );
};
FieldSet.propTypes = {
  title: PropTypes.string.isRequired,
};
export default FieldSet;
